$(function() {
    const $logoMenu = $('.logo');
    const $teamMenu = $('.team-menu');
    const $intro = $('#intro');
    const $team = $('#team');

    // if (window.location.hash === '#about-us') {
    //     $team.fadeIn(300);
    // } else {
        $intro.fadeIn(300);
    // }
    //
    // $teamMenu.click(function () {
    //     window.location.hash = '#about-us'
    //     $intro.fadeOut(300, function() {
    //         $team.fadeIn();
    //     });
    // });
    // $logoMenu.click(function () {
    //     window.location.hash = ''
    //     $team.fadeOut(300, function() {
    //         $intro.fadeIn();
    //     });
    // });
});

